import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { withStyles } from "@material-ui/core/styles";
const styles = {
  snackbarStyleViaContentProps: {
    backgroundColor: "#e26d50",
  },
  snackbarStyleViaNestedContent: {
    backgroundColor: "#e26d50",
    color: "white",
  },
};
function CustomSnackbar({ classes, openSnackbar, closeSnackbar, message }) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={openSnackbar}
      autoHideDuration={3000}
      ContentProps={{
        "aria-describedby": "message-id",
        className: classes.snackbarStyleViaContentProps,
      }}
      message={
        <span id="message-id">
          <div style={{ color: "white", fontWeight: "bold" }}>{message}</div>
        </span>
      }
      onClose={closeSnackbar}
    />
  );
}
export default withStyles(styles)(CustomSnackbar);
