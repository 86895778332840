// VideoContext.js

import React, { createContext, useContext, useState } from "react";

export const VideoContext = createContext();

export const VideoProvider = ({ children }) => {
  const [trimmedVideoFile, setTrimmedVideoFile] = useState(null);
  const [videoBlobUrl, setVideoBlobUrl] = useState(null);

  return (
    <VideoContext.Provider
      value={{
        trimmedVideoFile,
        setTrimmedVideoFile,
        videoBlobUrl,
        setVideoBlobUrl,
      }}
    >
      {children}
    </VideoContext.Provider>
  );
};
export const useVideo = () => useContext(VideoContext);
