// CameraStreamContext.js
import React, { createContext, useState, useEffect } from 'react';

export const CameraStreamContext = createContext();

export const CameraStreamProvider = ({ children }) => {
  const [cameraStream, setCameraStream] = useState(null);

  useEffect(() => {
    const startCameraStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        setCameraStream(stream);
      } catch (error) {
        console.error('Error accessing the camera:', error);
      }
    };

    startCameraStream();

    // Cleanup function to stop the camera when component unmounts
    return () => {
      if (cameraStream) {
        cameraStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  return (
    <CameraStreamContext.Provider value={{ cameraStream }}>
      {children}
    </CameraStreamContext.Provider>
  );
};
