import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import CustomSnackbar from "../../components/snackbar/CustomSnackbar";
import "./support.scss";
import {
  logo,
  fb_oval_icon,
  insta_oval_icon,
  twitter_oval_icon,
  support_footer_cover,
  linkedin_icon,
  twitter_icon,
  recolyse_footer_logo,
  insta_icon,
  facebook_icon,
  olindiasLogo,
} from "../../assets/images/index";
import { useUser } from "../../state/UserContext";

const Support = () => {
  const [name, setName] = useState("");
  const [adress, setAdress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { userData } = useUser();

  const navigate = useNavigate();

  const handleSupportForm = () => {
    setIsLoading(true);
    setAdress("");
    setMessage("");
    setName("");
    setPhoneNumber("");
  };

  const verifyIfUserIsConnected = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      return true;
    } else return false;
  };

  const handleCloseSnackbar = () => {
    setIsLoading(false);
  };
  return (
    <div className="container" style={{ minWidth: "100%" }}>
      <CustomSnackbar
        openSnackbar={isLoading}
        message={"Please wait we are processing your video..."}
        closeSnackbar={handleCloseSnackbar}
        autoHideDuration={null}
      />

      <div className="section-zero">
        <img
          src={logo}
          alt="logo"
          className="logo"
          onClick={() => navigate("/")}
        />

        {!userData && (
          <div className="support-header-connect-wrapper">
            <div className="support-btns-wrapper">
              <button
                className="support-sign-up-button"
                onClick={() => navigate("/signUp")}
              >
                <span>Create Account</span>
              </button>
              <button
                className="support-btn"
                onClick={() => {
                  if (verifyIfUserIsConnected()) {
                    navigate("/");
                  } else {
                    navigate("/login");
                  }
                }}
              >
                <span>Login</span>
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="section-one">
        <div className="form-container">
          <div className="support-title-container">
            <div className="support-title">
              <p>Get in touch with us.</p>
              <p>We're here to assist you.</p>
            </div>
            <div className="social-media-oval-container">
              <a href="https://www.facebook.com/Olindias.tn/">
              <img src={fb_oval_icon} alt="facebook" />
              </a>

              <a href="https://www.instagram.com/olindias.tn/">
              <img src={insta_oval_icon} alt="instagram" />
              </a>
              <a href="https://x.com/Olindias_TN">
              <img src={twitter_oval_icon} alt="twitter" />
              </a>
             
              
             
             
            </div>
          </div>

          <form onSubmit={handleSupportForm}>
            <div className="support-form-inputs">
              <label className="support-label">Your Name</label>
              <input
                className="support-input"
                onChange={(e) => setName(e.target.value)}
                type="text"
              />
            </div>
            <div className="support-form-inputs">
              <label className="support-label">Email Address</label>
              <input
                className="support-input"
                onChange={(e) => setAdress(e.target.value)}
                type="text"
              />
            </div>
            <div className="support-form-inputs">
              <label className="support-label">Phone Number (optional)</label>
              <input
                className="support-input"
                onChange={(e) => setPhoneNumber(e.target.value)}
                type="text"
              />
            </div>
            <div className="support-form-inputs">
              <label className="support-label">Message</label>
              <textarea
                className="support-input"
                rows="2"
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            <div className="support-btn" style={{ marginTop: "30px" }}>
              <button type="submit">Leave us a message</button>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M12 4.96484L10.59 6.37484L16.17 11.9648H4V13.9648H16.17L10.59 19.5548L12 20.9648L20 12.9648L12 4.96484Z"
                  fill="white"
                />
              </svg>
            </div>
          </form>
        </div>
      </div>
      <FooterOne />
      <FooterThree />
      <LastFooter />
    </div>
  );
};

const FooterOne = () => {
  return (
    <div
      className="f1-container"
      style={{
        backgroundImage: `url(${support_footer_cover})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      }}
    >
      <div className="f1-assist-txt-container">
        <div className="f1-assist-contact">Contact Info</div>
        <div className="f1-assist-txt">
          <p> We are always happy </p>
          <p> to assist you</p>
        </div>
      </div>

      <div className="f1-info-section">
        <p>Email Address</p>

        <p>support@recolyse.com</p>
        <div>Assistance hours: Monday - Friday 8 am to 5 pm EST</div>
      </div>

      <div className="f1-info-section">
        <p>Number</p>

        <p>(216) 55 890 755</p>
        <div>Assistance hours: Monday - Friday 8 am to 5 pm EST</div>
      </div>
    </div>
  );
};

const FooterTwo = () => {
  return (
    <div className="f2-container">
      <div className="subscribe-container">
        <div className="f2-subscribe-txt">Subscribe to our Newsletter</div>
        <div className="f2-subscribe-sub-txt">
          Subscribe for Updates: Stay informed about the latest investor
          updates, financial results, and announcements by subscribing to our
          newsletter.
        </div>
      </div>
    </div>
  );
};

const FooterThree = () => {
  return (
    <div className="f3-container">
      <div className="f3-logo">
        <img src={recolyse_footer_logo} />
      </div>
      <div className="f3-social-media-container">
        <div className="f3-social-media">
        <a href="https://www.facebook.com/Olindias.tn/">
        <img src={facebook_icon} />
              </a>
              <a href="https://x.com/Olindias_TN">
              <img src={twitter_icon} />
              </a>
              <a href="https://www.instagram.com/olindias.tn/">
              <img src={insta_icon} />
              </a>
              <a href="https://www.linkedin.com/company/olindias">
              <img src={linkedin_icon} />
              </a>
          
         
         
        
        </div>
        <div className="f3-social-media-txt">
          <p>Tunisia, knowledge square Monastir</p>
          <p>Phone: +216 55 890 755</p>
          <p>Email: support@recolyse.com</p>
        </div>
      </div>
    </div>
  );
};

const LastFooter = () => {
  return (
    <div className="f4-container">
      <p>
        © Copyright 
        <span style={{marginLeft : "5px",marginRight : "5px"}}>Recolyse</span>
        . All Rights {"  "}Reserved
      </p>
      <a
        href="https://www.olindias.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          display: "flex",
          alignItems: "center",
          textDecoration: "none",
        }}
      >
        <span
          style={{
            color: "#ffffff",
            fontSize: "14px",
            fontWeight: "500",
            fontFamily: "Inter",
            paddingRight: "10px",
          }}
        >
          Powered By
        </span>
        <img
          src={olindiasLogo}
          alt="Company Logo"
          style={{ width: "100px" }}
        />
      </a>
    </div>
  );
};

export default Support;
