import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { hydrate, render } from "react-dom";

const rootElement = document.getElementById("root");

// Check if there's already content in the root element (for SSR or hydration)
if (rootElement.hasChildNodes()) {
  // Use hydrate if there are already pre-rendered nodes
  ReactDOM.hydrate(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    rootElement
  );
} else {
  // Otherwise, use render for a standard React app
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

// Report web vitals
reportWebVitals();
