import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { background_success } from "../../../assets/images";
import { useUser } from "../../../state/UserContext";

const SignedPage = () => {
  const { userData } = useUser();
  const navigate = useNavigate();

  const redirection = () => {
    if (userData) {
      navigate("/");
    }
  };
  useEffect(() => {
    redirection();
  }, []);
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${background_success})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      }}
    >
      <div
        style={{
          borderRadius: "32px",
          padding: "50px",
          maxWidth: "750px",
          flexDirection: "column",
          gap: "20px",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          backgroundColor: "#fff",
        }}
      >
        <img
          src="https://recolyse.com/logo.png"
          alt="logo"
          style={{
            maxWidth: "180px",
            maxHeight: "120px",
            marginBottom: "-20px",
          }}
        />
        <h1
          style={{
            fontSize: "36px",
            fontWeight: "700",
            fontFamily: "Inter",
            color: "#e86747",
          }}
        >
          Signed Up Successfully!
        </h1>
        <p
          style={{
            fontSize: "22px",
            fontWeight: "700",
            fontFamily: "Inter",
            color: "#4a4a4a",
          }}
        >
          Your are successfully created your account with Recolyse, please check
          your email to activate your account.
        </p>
      </div>
    </div>
  );
};

export default SignedPage;
