import React, { useState, useEffect } from "react";
import { avatar, man, message, women } from "../../../assets/images";
import Moment from "moment";
import { useUser } from "../../../state/UserContext";
import style from "./productDetails.module.scss";
import api from "../../../Intercepters/AuthIntercepter";

export default function ProductComments({ product }) {
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  const [showComments, setShowComments] = useState(3);
  const { userData } = useUser();
  const styles = {
    fontSize: "large",
    fontWeight: "bold",
    width: "52px",
    height: "52px",
    borderRadius: "50px",
    color: "white",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#fb8365",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userId = userData._id;
    const productId = product._id;
    try {
      const res = await api.post(
        `/comments/add-comment-to-product/${product?._id}`,
        {
          userId: userId,
          recent_text: comment,
        }
      );
      setComment("");
      setComments([...comments, res.data]);
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  const fetchComments = async () => {
    try {
      const response = await api.post(`/comments/get-product-comments`, {
        product: product?._id,
      });
      setComments(response.data);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  const toggleAll = (e) => {
    e.preventDefault();
    if (showComments > 3) {
      setShowComments(3);
    } else {
      console.log("commmmmmmmments : ", comments.length);
      setShowComments(comments.length);
    }
  };

  useEffect(() => {
    fetchComments();
  }, []);

  return (
    <>
      <hr />
      <p className={style["reviews-length"]}>{comments.length} Reviews</p>
      {comments.slice(0, showComments).map((comment, index) => (
        <div key={`comment-${index}`} className={style["user-review"]}>
          <div className={style["user-review-wrapper"]}>
            {comment.user?.picture?.length > 0 ? (
              <img
                alt="profile-picture"
                name="picture"
                src={
                  comment.user?.picture.includes("upload")
                    ? `${process.env.REACT_APP_API_URL}/${comment.user?.picture}`
                    : comment.user?.picture
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    userData?.gender === "male" || userData?.gender === ""
                      ? man
                      : women;
                }}
              />
            ) : (
              <div className={style["initials"]}>
                <img
                  src={
                    userData?.gender === "male" || userData?.gender === ""
                      ? man
                      : women
                  }
                  alt="default img"
                />
              </div>
            )}
            <div>
              <p className={style["user-name"]}>
                {comment.user.firstName} {comment.user.lastName}
              </p>
              <p className={style["user-role"]}>{comment.user.role}</p>

              {/* <Rating /> */}
            </div>
            {/* {userData._id!==comment.user._id && <p className="text-[#FF6154] text-[16px] font-[400]">Follow</p>} */}
          </div>
          <div className={style["user-comment"]}>{comment.recent_text}</div>
          <div className={style["comment-date"]}>
            {Moment(comment.created_at).format("MMM d, yyyy hh:mm")}
          </div>
        </div>
      ))}
      {comments.length > 3 && showComments == 3 && (
        <button className={style["view-more"]} onClick={(e) => toggleAll(e)}>
          View All Reviews
        </button>
      )}
      {comments.length > 3 && showComments == comments.length && (
        <button className={style["view-more"]} onClick={(e) => toggleAll(e)}>
          Collapse Reviews
        </button>
      )}
      <div className={style["comment"]}>Comment</div>
      <div className={style["comment-wrapper"]}>
        <form>
          <textarea
            rows="7"
            placeholder="Add your comment here ..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <div className={style["send-comment"]}>
            <button onClick={(e) => handleSubmit(e)}>
              <span>Send comment</span>
              <img src={message} alt="send comment" />
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
