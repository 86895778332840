import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from "react";
import { useUser, Context } from "../../../state/UserContext";

import style from "./listProducts.module.scss";
import { NoData } from "../../../assets/images";
import ProductCard from "./ProductCard";
import { CircularProgress } from "@mui/material";
import LoginPopUp from "../../auth/login-page/LoginPopUp";
import { Helmet } from "react-helmet-async";
import api from "../../../Intercepters/AuthIntercepter";
import CategoriesSelection from "../../../components/category-selection/CategoriesSelection";

const ListProduct = ({ searchQuery }) => {
  const { userData } = useUser();
  const { products, setProducts } = useContext(Context);
  const {
    displayProducts,
    setDisplayProducts,
    isSearchingProducts,
    setIsSearchingProducts,
  } = useContext(Context);
  const [isLoading, setIsLoading] = useState(true);
  const [openLoginPopUp, setOpenLoginPopUp] = useState(false);
  const [myProductsRatings, setMyProductsRatings] = useState([]);
  const [page, setPage] = useState(1);
  const observer = useRef();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const [totalPages, setTotalPages] = useState(2);
  const [allProducts, setAllProducts] = useState([]);

  const [categoriesChecked, setCategoriesChecked] = useState(["All"]);
  const [filter, setFilter] = useState("");
  const [choosenType, setChoosenType] = useState("All");
  const [videoTypes, setVideosTypes] = useState([]);

  const PRODUCTS_PER_PAGE = 9;

  const LoadingIndicator = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "30px",
      }}
    >
      <CircularProgress />
    </div>
  );

  const getAllProducts = useCallback(
    async (page = 1, limit = PRODUCTS_PER_PAGE) => {
      try {
        const categoryParam = encodeURIComponent(categoriesChecked.join(","));
        setIsLoadingMore(true);
        const res = await api.get(
          `/products?page=${page}&limit=${limit}&categories=${categoryParam}`
        );
        // const response = await res.json();
        if (!Array.isArray(res.data.products)) {
          setHasMore(false);
          return { products: [] };
        }

        setTotalPages(res.data.totalPages);

        return res.data;
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setIsLoading(false);
        setIsLoadingMore(false);
      }
    },
    [setProducts, categoriesChecked]
  );

  const filterVideos = (videos) => {
    const filtered = categoriesChecked.includes("All")
      ? videos
      : videos.filter((video) =>
          video.product?.categories.some((category) =>
            categoriesChecked.includes(category)
          )
        );
    return filtered;
  };

  const loadMoreProducts = useCallback(async () => {
    if (isLoadingMore || !hasMore || page >= totalPages) return;

    setIsLoadingMore(true);
    try {
      const nextPage = page + 1;
      const response = await getAllProducts(nextPage, PRODUCTS_PER_PAGE);

      if (Array.isArray(response.products)) {
        setAllProducts((prevProducts) => [
          ...prevProducts,
          ...response.products,
        ]);
        // setDisplayProducts((prevDisplay) => [
        //   ...prevDisplay,
        //   ...response.products,
        // ]);
        setDisplayProducts((prevDisplay) => {
          if (!Array.isArray(prevDisplay)) {
            return response.products;
          }
          return [...prevDisplay, ...response.products];
        });
        setPage(nextPage);
        setHasMore(nextPage < response.totalPages); // Set hasMore based on response
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error loading more products:", error);
    } finally {
      setIsLoadingMore(false);
    }
  }, [isLoadingMore, hasMore, page, getAllProducts, categoriesChecked]);

  const lastProductElementRef = useCallback(
    (node) => {
      if (isLoadingMore || !hasMore) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            loadMoreProducts();
          }
        },
        { threshold: 0.1 } // Trigger when 10% of the product is visible
      );

      if (node) observer.current.observe(node);
    },
    [isLoadingMore, hasMore, loadMoreProducts, isSearchingProducts]
  );

  useEffect(() => {
    const fetchInitialProducts = async () => {
      setIsLoading(true);
      const response = await getAllProducts(1, PRODUCTS_PER_PAGE);

      const filteredProducts = filterVideos(response.products);

      setAllProducts(response.products);
      setDisplayProducts(filteredProducts);
      setPage(1);
      setHasMore(response.products.length >= PRODUCTS_PER_PAGE);
      setIsLoading(false);
    };
    if (isSearchingProducts && displayProducts?.length > 0) {
      // setTotalPages(displayProducts.totalPages);
      // setPage(1);
      setAllProducts(displayProducts.slice(0, PRODUCTS_PER_PAGE));
    } else {
      fetchInitialProducts();
    }
  }, [getAllProducts, isSearchingProducts, categoriesChecked]);

  return (
    <>
      <Helmet>
        <title>Recolyse | AI-Enhanced Startup Product Store.</title>
        <meta
          name="description"
          content="Browse our AI-based selection of startup products. Recolyse connects you with the latest in tech and AI solutions just for your needs."
        />
      </Helmet>
      <div>
        <hr style={{ margin: "5px 20px 0 20px" }} />
        <div className={style["container"]}>
          {/* <div className={style["header"]}>Product Store</div> */}
          <CategoriesSelection
            style={{ flex: "0 0 auto" }}
            categoriesChecked={categoriesChecked}
            setCategoriesChecked={setCategoriesChecked}
            filter={filter}
            setFilter={setFilter}
            choosenType={choosenType}
            setChoosenType={setChoosenType}
            videoTypes={videoTypes}
            isExplore={false}
          />
          {isLoading ? (
            <LoadingIndicator />
          ) : isSearchingProducts && displayProducts.length > 0 ? (
            <div className={style.products}>
              {displayProducts.map((product, index) => {
                if (displayProducts.length === index + 1) {
                  // Attach the observer to the last product
                  return (
                    <div
                      ref={lastProductElementRef}
                      key={product._id + " isSearching"}
                    >
                      <ProductCard
                        product={product}
                        myProductsRatings={myProductsRatings}
                        userData={userData}
                        setOpenLoginPopUp={setOpenLoginPopUp}
                        isMuted={isMuted}
                        toggleMute={() => setIsMuted(!isMuted)}
                      />
                    </div>
                  );
                } else {
                  return (
                    <ProductCard
                      key={product._id}
                      product={product}
                      myProductsRatings={myProductsRatings}
                      userData={userData}
                      setOpenLoginPopUp={setOpenLoginPopUp}
                      isMuted={isMuted}
                      toggleMute={() => setIsMuted(!isMuted)}
                    />
                  );
                }
              })}
            </div>
          ) : !isSearchingProducts && allProducts.length > 0 ? (
            <div className={style.products}>
              {allProducts.map((product, index) => {
                if (allProducts.length === index + 1) {
                  // Attach the observer to the last product
                  return (
                    <div ref={lastProductElementRef} key={product._id}>
                      <ProductCard
                        product={product}
                        myProductsRatings={myProductsRatings}
                        userData={userData}
                        setOpenLoginPopUp={setOpenLoginPopUp}
                        isMuted={isMuted}
                        toggleMute={() => setIsMuted(!isMuted)}
                      />
                    </div>
                  );
                } else {
                  return (
                    <ProductCard
                      key={product._id + " noSearching"}
                      product={product}
                      myProductsRatings={myProductsRatings}
                      userData={userData}
                      setOpenLoginPopUp={setOpenLoginPopUp}
                      isMuted={isMuted}
                      toggleMute={() => setIsMuted(!isMuted)}
                    />
                  );
                }
              })}
            </div>
          ) : (
            !isLoading &&
            displayProducts?.length === 0 && (
              <div className={style["no-data"]}>
                <img src={NoData} alt="No Products Found" />
                <p>No Products Found</p>
              </div>
            )
          )}
        </div>
        {/* Loading indicator for infinite scroll */}
        {isLoadingMore && page != 1 && <LoadingIndicator />}
        <LoginPopUp
          open={openLoginPopUp}
          handleClose={() => setOpenLoginPopUp(false)}
        />
      </div>
    </>
  );
};

export default ListProduct;
