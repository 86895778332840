import { Alert, CircularProgress, Snackbar, TextField } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { email_icon } from "../../../assets/images";
import PrimaryButton from "../../../components/primary-button/PrimaryButton";
import api from "../../../Intercepters/AuthIntercepter";
import style from "./forgot.module.scss";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [isSent, setIsSent] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const sendResetLink = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await api.post(`/auth/forgot-password`, { email });
      setMessage(response.data.message);
      setStatus(response.data.success);
      setOpenSnackbar(true);
      setIsSent(true);
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className={style["forgot-container"]}>
      <div className={style["forgot-content"]}>
        <h1>Forgot Password</h1>

        {!isSent ? (
          <>
            <p>
              Enter your Email address and we'll send you a link to reset your
              password.
            </p>
            <form onSubmit={(e) => sendResetLink(e)}>
              <div>
                <label className={style["input-label"]}>Email Adress</label>
                <TextField
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  id="outlined-basic"
                  variant="outlined"
                  sx={{
                    mt: 1,
                    color: "#E86747",
                    width: "100%",
                  }}
                  color="primary"
                  type="email"
                  required
                />
              </div>

              <div
                style={{
                  marginTop: "35px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <PrimaryButton
                  type="submit"
                  disabled={!email}
                  text={
                    isLoading ? (
                      <CircularProgress size={24} className="white" />
                    ) : (
                      "Send Reset Link"
                    )
                  }
                  full="w-full"
                />
              </div>
            </form>
          </>
        ) : (
          <div className={style["received-container"]}>
            <img src={email_icon} alt="" />
            <p>
              Please check your email inbox, you will receive a password reset
              link shortly
            </p>
          </div>
        )}

        {/* Go to Sign Up */}
        <div className={style["have-password"]}>
          <button onClick={() => navigate("/login")}>Back to Login</button>
        </div>
      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={status ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </section>
  );
};

export default ForgotPassword;
