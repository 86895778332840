import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../Intercepters/AuthIntercepter";
import { useUser } from "../../../state/UserContext";

const ActivationPage = () => {
  const { verificationCode } = useParams();
  const navigate = useNavigate();
  const { userData, setUser } = useUser();

  useEffect(() => {
    const verifyUser = async () => {
      try {
        const response = await api.post(`/auth/verifyuser/${verificationCode}`);
        navigate("/login");
      } catch (error) {
        console.error("Error during verification", error);
      }
    };

    if (!userData) {
      verifyUser();
    } else {
      navigate("/");
    }
  }, [verificationCode, navigate, setUser]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <img
        src="https://www.recolyse.com/logo.png"
        alt="logo"
        style={{ maxWidth: "400px", width: "100%" }}
      />
      <span> Activation...</span>
    </div>
  );
};

export default ActivationPage;
