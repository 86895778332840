import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Alert,
  CircularProgress,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PrimaryButton from "../../../components/primary-button/PrimaryButton";
import api from "../../../Intercepters/AuthIntercepter";
import style from "./forgot.module.scss";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { resetToken } = useParams();

  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const resetPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await api.post(`/auth/reset-password/${resetToken}`, {
        password,
      });
      console.log(response);
      if (response.data.success) {
        navigate("/");
      } else {
        setMessage(response.data.message);
        setStatus(response.data.success);
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const passwordsMatch = password === confirmPassword;

  return (
    <section className={style["forgot-container"]}>
      <div className={style["forgot-content"]}>
        {/* <h1>Reset Password</h1> */}
        <h1>Create Password</h1>

        <form onSubmit={(e) => resetPassword(e)}>
          <div>
            <label className={style["input-label"]}>New Password</label>
            <TextField
              variant="outlined"
              sx={{
                mt: 1,
                color: "#E86747",
                width: "100%",
                marginBottom: "20px",
              }}
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={
                        showPassword ? "Hide password" : "Show password"
                      }
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div>
            <label className={style["input-label"]}>Confirm Password</label>
            <TextField
              variant="outlined"
              sx={{ mt: 1, color: "#E86747", width: "100%" }}
              type={showPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={
                        showPassword ? "Hide password" : "Show password"
                      }
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          {passwordsMatch ? null : (
            <p style={{ color: "red" }}>Passwords do not match.</p>
          )}

          <div style={{ marginTop: "35px", marginBottom: "10px" }}>
            <PrimaryButton
              type="submit"
              disabled={
                !password || !confirmPassword || isLoading || !passwordsMatch
              }
              text={
                isLoading ? (
                  <CircularProgress size={24} className="white" />
                ) : (
                  "Create Password"
                )
              }
              full="w-full"
            />
          </div>
        </form>
      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={status ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </section>
  );
};

export default ResetPassword;
