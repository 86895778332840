import React, { useContext, useEffect, useRef, useState } from "react";
import {
  cam,
  mic,
  recordIcon,
  screen,
  screen_cam,
  trash,
  uploadIcon,
  record_bubble,
  record_stop,
  rec_icon,
  import_video_icon,
  cam_off,
  cam_on,
  mic_off,
} from "../../assets/images";
import { RiVideoAddFill } from "react-icons/ri";
import style from "./record.module.scss";
import "./recordPageExample.scss";
import { Box, Modal } from "@mui/material";
import logo from "../../assets/images/logo.svg";
import Draggable from "react-draggable";
import { useNavigate } from "react-router-dom";
import { Context, useUser } from "../../state/UserContext";
import { CameraStreamContext } from "./CameraStreamContext";
import api from "../../Intercepters/AuthIntercepter";
import CustomSnackbar from "../snackbar/CustomSnackbar";

const stop_icon = (
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.479553"
      y="0.240234"
      width="28.08"
      height="28.08"
      rx="14.04"
      fill="#363342"
    />
    <g clip-path="url(#clip0_6887_6537)">
      <path
        d="M19.1993 8.04004H9.83927C8.9777 8.04004 8.27927 8.73847 8.27927 9.60004V18.96C8.27927 19.8216 8.9777 20.52 9.83927 20.52H19.1993C20.0608 20.52 20.7593 19.8216 20.7593 18.96V9.60004C20.7593 8.73847 20.0608 8.04004 19.1993 8.04004Z"
        fill="#E5E5FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_6887_6537">
        <rect
          width="12.48"
          height="18.72"
          fill="white"
          transform="translate(8.27954 4.91992)"
        />
      </clipPath>
    </defs>
  </svg>
);

const start_icon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clipRule="evenodd"
      d="M7.18638 4.82031C6.24336 4.82031 5.47974 5.58471 5.47974 6.52695V14.0345C5.48928 14.4807 5.67323 14.9054 5.99218 15.2176C6.31112 15.5298 6.73968 15.7046 7.18599 15.7046C7.6323 15.7046 8.06085 15.5298 8.37979 15.2176C8.69874 14.9054 8.8827 14.4807 8.89224 14.0345V6.52695C8.89224 5.58393 8.12862 4.82031 7.18638 4.82031ZM13.3281 4.82031C12.3859 4.82031 11.6222 5.58471 11.6222 6.52695V14.0345C11.6318 14.4807 11.8157 14.9054 12.1347 15.2176C12.4536 15.5298 12.8822 15.7046 13.3285 15.7046C13.7748 15.7046 14.2034 15.5298 14.5223 15.2176C14.8412 14.9054 15.0252 14.4807 15.0347 14.0345V6.52695C15.0347 5.58393 14.2703 4.82031 13.3281 4.82031Z"
      fill="#E5E5FF"
    />
  </svg>
);

function ModalContent({ openRecord, closeRecord }) {
  const [open, setOpen] = useState(false);
  const contextValue = useContext(CameraStreamContext);
  const { cameraStream, setCameraStream } = contextValue || {
    cameraStream: null,
    setCameraStream: () => {},
  };

  // const {
  //   recordStarted,
  //   setRecordStarted,
  //   elapsedTime,
  //   setElapsedTime,
  //   showRecordButtons,
  //   setShowRecordButtons,
  // } = useRecording();

  const [camStream, setCamStream] = useState(null);

  const { openProductPopUp, setOpenProductPopUp } = React.useContext(Context);
  const { openChooseProduct, setOpenChooseProduct } = React.useContext(Context);
  const { openRecordPopUp, setOpenRecordPopUp } = React.useContext(Context);
  const { blobFile, setBlobFile } = React.useContext(Context);
  const [microphones, setMicrophones] = useState([]);
  const [cameras, setCameras] = useState([]);
  const [optionVideo, setOptionVideo] = useState("Screen and Camera");
  const [isOpen1, setIsOpen1] = useState(false);
  let [dragInfo, setDragInfo] = useState(null);
  // record states
  const [withCamera, setWithCamera] = useState(false);
  const [countDown, setCountdown] = useState(false);
  const [counter, setCounter] = useState(3);
  const [counterModal, setCounterModal] = useState(false);
  const [screenStream, setScreenStream] = useState(null);
  const [startTime, setStartTime] = useState(0);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [deviceRecorder, setDeviceRecorder] = useState(null);
  const [chunks, setChunks] = useState([]);
  const [cameraSize, setCameraSize] = useState("small");
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [recordStarted, setRecordStarted] = useState(false);
  const [screenRecorder, setScreenRecorder] = useState(null);
  const [cameraRecorder, setCameraRecorder] = useState(null);
  const cameraVideoRef = useRef(null);
  const screenVideoRef = useRef(null);
  const [playerRef, setPlayerRef] = useState(null);
  const { userData } = useUser();
  const recordOffSpan = useRef(null);
  const [optionCam, setOptionCam] = useState(
    cameras.length > 0 ? cameras[0].label : ""
  );
  const [isOpen2, setIsOpen2] = useState(false);

  const [optionMic, setOptionMic] = useState(
    microphones.length > 0 ? microphones[0].label : ""
  );
  const [isOpen3, setIsOpen3] = useState(false);

  const toggleDropdown1 = () => {
    setIsOpen1(!isOpen1);
  };
  const toggleDropdown2 = () => {
    setIsOpen2(!isOpen2);
  };
  const toggleDropdown3 = () => {
    setIsOpen3(!isOpen3);
  };

  const navigate = useNavigate();

  // ++++++++++

  const handleOpenCounterModal = () => setCounterModal(true);
  const handleCloseCounterModal = () => setCounterModal(false);

  const handleStartRecording = async (event) => {
    event.preventDefault();
    const formData = {
      optionVideo,
      optionCam,
      optionMic,
      countDown,
    };
    setOpen(false);
  };

  const [elapsedTime, setElapsedTime] = useState(60000);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [selectedMicrophone, setSelectedMicrophone] = useState(null);

  const [micState, setMicState] = useState(true);
  const [camState, setCamState] = useState(true);

  const handleMicState = () => {
    setMicState(!micState);
  };
  const handleCamState = () => {
    if (optionVideo != "Camera" && optionVideo != "Screen and Camera")
      setCamState(!camState);
  };

  const handleCameraSelection = (camera) => {
    setSelectedCamera(camera);
  };

  const handleMicrophoneSelection = (microphone) => {
    setSelectedMicrophone(microphone);
  };

  // get microphones and cameras
  async function getMicrophonesAndCams() {
    try {
      const devices = await navigator.mediaDevices?.enumerateDevices();
      const audioDevices = devices.filter(
        (device) => device.kind === "audioinput"
      );
      const cameraDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );
      setCameras(cameraDevices);
      setMicrophones(audioDevices);

      if (cameraDevices.length > 0) {
        setOptionCam(cameraDevices[0].label);
      }
      if (microphones.length > 0) {
        setOptionMic(microphones[0].label);
      }
    } catch (error) {
      console.error("Error enumerating audio devices:", error);
    }
  }

  useEffect(() => {
    getMicrophonesAndCams();
    if (cameras.length == 0) {
      setOptionVideo("Screen");
    }
  }, [cameras]);

  const handleOpen = () => {
    getMicrophonesAndCams();
    setOpenRecordPopUp(true);
  };

  const [showCreateOption, setShowCreateOption] = useState(false);

  const handleCreateVideoButton = () => {
    if (!userData) {
      navigate("/login");
    } else {
      setShowCreateOption(!showCreateOption);
    }
  };

  const createVideoItems = [
    {
      title: "Record Video",
      icon: rec_icon,
      link: "/record",
    },
    {
      title: "Upload Video",
      icon: import_video_icon,
      link: "/upload",
    },
  ];

  const handleClose = () => setOpenRecordPopUp(false);

  const browserMimetype = () => {
    // Detect Chrome
    if (navigator.userAgent.indexOf("Chrome") > -1) {
      return "video/webm";
    }
    // Detect Safari
    else if (navigator.userAgent.indexOf("Safari") > -1) {
      return "video/mp4";
    }
    // Default to webm if neither Chrome nor Safari
    else {
      return "video/webm";
    }
  };
  const mimtype = browserMimetype();

  var cameraChunks = [];
  // Function to check if the device is a mobile device
  function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  // Start Recording
  async function recordOn(selectedCamera, selectedMicrophone) {
    localStorage.setItem("videoBlobUrl", null);
    sessionStorage.setItem("videoBlobUrl", null);
    localStorage.setItem("videoBase64", null);
    sessionStorage.setItem("hasReloaded", false);

    let chunks = [];
    setRecordStarted(true);
    setShowRecordButtons(true);
    let count = 3;

    try {
      if (isMobileDevice()) {
        alert(
          "Screen recording is not supported on mobile devices yet. Please use our Recolyse App."
        );
        return;
      }

      //camera and screen
      if (optionVideo == "Screen and Camera") {
        // setWithCamera(true);
        setRecordStarted(true);

        // return;
      }
      if (optionVideo == "Screen") {
        let audioTrack, videoTrack, stream;

        try {
          await navigator.mediaDevices
            .getDisplayMedia({
              audio: micState,
              video: { mediaSource: "screen" },
            })
            .then(async (displayStream) => {
              await cowntFunction();

              [videoTrack] = displayStream.getVideoTracks();
              if (micState) {
                const audioStream = await navigator.mediaDevices
                  .getUserMedia({
                    audio: selectedMicrophone
                      ? { deviceId: selectedMicrophone.deviceId }
                      : true,
                  })
                  .catch((e) => {
                    throw e;
                  });
                [audioTrack] = audioStream.getAudioTracks();

                stream = new MediaStream([videoTrack, audioTrack]);
              } else {
                stream = new MediaStream([videoTrack]);
              }
            });
        } catch (err) {
          console.error(`Error: ${err}`);
        }

        let deviceRecord = new MediaRecorder(stream, {
          mimeType: mimtype,
        });

        deviceRecord.ondataavailable = (event) => {
          chunks.push(event.data);
        };

        if (countDown) {
          handleOpenCounterModal();
          const countdownInterval = setInterval(() => {
            if (count === 0) {
              clearInterval(countdownInterval);
              handleCloseCounterModal();

              setScreenStream(stream);
              setStartTime(Date.now());
              deviceRecord.start();
            } else {
              setCounter(count);
              count--;
            }
          }, 1000);
        } else {
          setScreenStream(stream);
          setStartTime(Date.now());
          deviceRecord.start();
        }

        setDeviceRecorder(deviceRecord);
        setChunks(chunks);
        // console.log(deviceRecord);
      }

      // }

      //camera

      if (optionVideo === "Camera") {
        sessionStorage.setItem("onlyCamera", true);
        setWithCamera(true);

        await cowntFunction();

        const streamCamera = await navigator.mediaDevices.getUserMedia({
          audio: micState
            ? selectedMicrophone
              ? { deviceId: selectedMicrophone.deviceId }
              : true
            : false,
          video: camState
            ? selectedCamera
              ? { deviceId: selectedCamera.deviceId }
              : true
            : false,
        });

        let mediaRecorder = new MediaRecorder(streamCamera, {
          mimeType: mimtype,
        });

        if (countDown) {
          handleOpenCounterModal();
          const countdownInterval = setInterval(() => {
            if (count === 0) {
              clearInterval(countdownInterval);
              handleCloseCounterModal();
              setStartTime(Date.now());
              setCamStream(streamCamera);
              mediaRecorder.start();
            } else {
              setCounter(count);
              count--;
            }
          }, 1000);
        } else {
          setStartTime(Date.now());
          setCamStream(streamCamera);
          mediaRecorder.start();
        }

        const videoElement = document.getElementById("videoElement");

        videoElement.srcObject = streamCamera;
        mediaRecorder.ondataavailable = (event) => {
          cameraChunks.push(event.data);
        };

        setMediaRecorder(mediaRecorder);

        setChunks(cameraChunks);
      }
    } catch (error) {
      console.error("Error accessing media devices:", error);
    }
  }

  const cowntFunction = async () => {
    if (countDown) {
      handleOpenCounterModal();
      let count = 3;
      const countdownInterval = setInterval(() => {
        if (count === 0) {
          clearInterval(countdownInterval);
          handleCloseCounterModal();
        } else {
          setCounter(count);
          count--;
        }
      }, 1000);
      setCounter(3);
    }
  };

  useEffect(() => {
    if (recordStarted && optionVideo === "Screen and Camera") {
      // Start screen recording
      navigator.mediaDevices
        .getDisplayMedia({ video: true })
        .then((screenStream) => {
          setScreenStream(screenStream);
          const screenRecorder = new MediaRecorder(screenStream);
          setScreenRecorder(screenRecorder);
          screenRecorder.start();
        })
        .then(() => setStartTime(Date.now()))
        .catch((error) => {
          console.error("Error getting display media:", error);
        });

      // Start camera recording if not already active
      if (!camStream) {
        navigator.mediaDevices
          .getUserMedia({
            video: true,
            audio: micState
              ? selectedMicrophone
                ? { deviceId: selectedMicrophone.deviceId }
                : true
              : false,
          })
          .then((stream) => {
            setCamStream(stream); // Save stream in context to persist across page navigations
            const cameraRecorder = new MediaRecorder(stream);
            setCameraRecorder(cameraRecorder);
            cameraRecorder.start();

            // const videoElement = document.getElementById("videoElement");

            // if (videoElement) {
            //   videoElement.srcObject = stream;
            // }
          })
          .catch((error) => {
            console.error("Error getting user media:", error);
          });
      } else {
        const cameraRecorder = new MediaRecorder(camStream);
        setCameraRecorder(cameraRecorder);
        cameraRecorder.start();

        // const videoElement = document.getElementById("videoElement");
        // if (videoElement) {
        //   videoElement.srcObject = cameraStream;
        // }
      }
    }

    return () => {
      // Stop the recording if the component is unmounted
      if (screenRecorder && screenRecorder.state !== "inactive") {
        screenRecorder.stop();
      }
      if (cameraRecorder && cameraRecorder.state !== "inactive") {
        cameraRecorder.stop();
      }
      if (camStream) {
        camStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [recordStarted]);

  const stopRecording = () => {
    if (screenRecorder && screenRecorder.state !== "inactive") {
      screenRecorder.stop();
    }
    if (cameraRecorder && cameraRecorder.state !== "inactive") {
      cameraRecorder.stop();
    }

    // Stop the camera stream
    if (camStream) {
      camStream.getTracks().forEach((track) => {
        track.stop();
      });
      // setCamStream(null);
    }
  };
  const [videoFiles, setVideoFiles] = useState({ camera: null, screen: null });

  const handleDataAvailable = (e, isCamera) => {
    if (isCamera) {
      setVideoFiles((prevFiles) => ({ ...prevFiles, camera: e.data }));
    } else {
      setVideoFiles((prevFiles) => ({ ...prevFiles, screen: e.data }));
    }
  };

  useEffect(() => {
    if (videoFiles.camera && videoFiles.screen) {
      mixVideos(videoFiles.camera, videoFiles.screen);
    }
  }, [videoFiles]);

  const storeVideoInIndexedDB = async (blob) => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open("VideoDatabase", 2);

      request.onupgradeneeded = (event) => {
        const db = event.target.result;

        if (!db.objectStoreNames.contains("videos")) {
          db.createObjectStore("videos", { keyPath: "id" });
        }
      };

      request.onsuccess = (event) => {
        const db = event.target.result;

        if (!db.objectStoreNames.contains("videos")) {
          reject(new Error("Object store 'videos' not found"));
          return;
        }

        const transaction = db.transaction("videos", "readwrite");
        const objectStore = transaction.objectStore("videos");

        const videoData = { id: "videoBlob", video: blob };
        const addRequest = objectStore.put(videoData);

        addRequest.onsuccess = () => resolve();
        addRequest.onerror = (e) => reject(e);
      };

      request.onerror = (e) => reject(e);
    });
  };

  const mixVideos = async (cameraData, screenData) => {
    const formData = new FormData();
    formData.append(
      "videos",
      new Blob([screenData], { type: "video/mp4" }),
      "screen.mp4"
    );
    formData.append(
      "videos",
      new Blob([cameraData], { type: "video/mp4" }),
      "camera.mp4"
    );

    try {
      const response = await api.post(`/videos/overlay-two-videos`, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Make sure to set the correct content type for form data
        },
      });
      if (!response.statusText == "OK") {
        throw new Error("Error while overlaying videos");
      }
      const base64String = response.data.video.split(",")[1];
      const binaryString = window.atob(base64String);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);

      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      const blob = new Blob([bytes], { type: "video/mp4" });
      const videoUrl = URL.createObjectURL(blob);

      const base64 = await blobToBase64(blob);

      await storeVideoInIndexedDB(blob);

      // Optionally store a simple flag to check later if the video exists
      localStorage.setItem("videoStored", "true");
      // localStorage.setItem("videoBase64", base64);

      // console.log("Overlayed video URL:", videoUrl);
      // localStorage.setItem("videoBlobUrl", videoUrl);
      // sessionStorage.setItem("videoBlobUrl", videoUrl);

      navigate("/video");
    } catch (error) {
      console.error("Error during video overlay:", error);
    }
  };

  useEffect(() => {
    if (screenRecorder) {
      screenRecorder.ondataavailable = (e) => handleDataAvailable(e, false);
    }
    if (cameraRecorder) {
      cameraRecorder.ondataavailable = (e) => handleDataAvailable(e, true);
    }
  }, [screenRecorder, cameraRecorder]);

  // Timer
  useEffect(() => {
    let interval;

    if (startTime && recordStarted) {
      let count = 60000;

      interval = setInterval(() => {
        if (count > 0) {
          setElapsedTime(count);
          count -= 1000;
        } else {
          clearInterval(interval);
          setElapsedTime(0);
          RecordOff();
        }
      }, 1000);
    } else {
      clearInterval(interval);
      setElapsedTime(60000);
    }

    return () => clearInterval(interval);
  }, [startTime, recordStarted]);

  const formatTime = (milliseconds) => {
    const minutes = Math.floor(milliseconds / 60000);
    const seconds = Math.floor((milliseconds % 60000) / 1000);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const blobToBase64 = (blob) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };

  const [isLoading, setIsLoading] = useState(false);

  function RecordOff() {
    setIsLoading(true);
    sessionStorage.setItem("onlyCamera", false);

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    const cleanupMediaStreams = (stream) => {
      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };

    if (!document.hasFocus() && this?.isSafari()) {
      window.focus();
    }

    // Stop the camera recording
    if (mediaRecorder && mediaRecorder.state !== "inactive") {
      mediaRecorder.onstop = async () => {
        if (optionVideo === "Camera" || optionVideo === "Screen and Camera") {
          const blob = new Blob(chunks, { type: mimtype });
          setPlayerRef(blob);
          const videoUrl = URL.createObjectURL(blob);
          localStorage.setItem("videoBlobUrl", videoUrl);
          sessionStorage.setItem("videoBlobUrl", videoUrl);

          const base64 = await blobToBase64(blob);
          localStorage.setItem("videoBase64", base64);
          setBlobFile(blob);
          setIsLoading(false);
          navigate("/video");
        }
      };
      mediaRecorder.stop();
    }

    // Stop the camera recorder if active
    if (cameraRecorder || cameraRecorder?.state !== "inactive") {
      cameraRecorder?.stop();
      setCameraRecorder(null);
    }

    if (camStream) {
      cleanupMediaStreams(camStream);
      setCamStream(null);
    }

    if (screenStream) {
      cleanupMediaStreams(screenStream);
      setScreenStream(null);
    }

    // Force release camera in Safari
    async function forceReleaseCamera() {
      if (isSafari) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: true,
          });
          cleanupMediaStreams(stream);
        } catch (error) {
          console.error("Error forcing camera release: ", error);
        }
      }
    }

    if (isSafari) {
      forceReleaseCamera();
    }

    if (optionVideo === "Camera") {
      mediaRecorder &&
        (mediaRecorder.onstop = async () => {
          const blob = new Blob(chunks, { type: mimtype }); //webm;codecs=vp8
          setPlayerRef(blob);
          const videoUrl = URL.createObjectURL(blob);
          let recordedVideo = document.getElementById("recordedVideo");
          // hideCamera();
          localStorage.setItem("videoBlobUrl", videoUrl);
          sessionStorage.setItem("videoBlobUrl", videoUrl);

          // const base64 = await blobToBase64(blob);
          // localStorage.setItem("videoBase64", base64);

          await storeVideoInIndexedDB(blob);
          localStorage.setItem("videoStored", "true");

          setBlobFile(blob);
          localStorage.setItem("recordCamera", true);
          setIsLoading(false);
          navigate("/video");
        });
      if (camStream != null && camStream.getTracks().length > 0) {
        const tracks = camStream.getTracks();
        tracks.forEach((track) => track.stop());
        // setCamStream(null);
      }
      setMediaRecorder(null);
      cameraRecorder?.stop();
    } else if (optionVideo == "Screen and Camera") {
      setMediaRecorder(null);
      stopRecording();
    } else {
      deviceRecorder &&
        (deviceRecorder.onstop = async () => {
          const blob = new Blob(chunks, { type: mimtype }); // webm;codecs=vp8
          setPlayerRef(blob);
          const videoUrl = URL.createObjectURL(blob);
          let recordedVideo = document.getElementById("recordedVideo");
          // recordedVideo.srcObject = null;
          // recordedVideo.src = videoUrl;
          // hideCamera();
          await storeVideoInIndexedDB(blob);
          localStorage.setItem("videoStored", "true");

          localStorage.setItem("videoBlobUrl", videoUrl);
          sessionStorage.setItem("videoBlobUrl", videoUrl);
          setBlobFile(blob);
          setIsLoading(false);
          navigate("/video");
        });
    }
    // Cleanup
    deviceRecorder && deviceRecorder.stop();
    setRecordStarted(false);
    setDeviceRecorder(null);
    setMediaRecorder(null);
    setWithCamera(false);
    handleCamState(false);
    handleMicState(true);
    const screenTracks = screenStream && screenStream.getTracks();
    if (screenTracks) {
      screenTracks.forEach((track) => {
        if (track.readyState === "live") {
          track.stop();
        }
      });
    }

    setMediaRecorder(null);
  }

  if (screenStream) {
    screenStream.getVideoTracks()[0].onended = () => {
      RecordOff();
    };
  }

  const [fullScreen, setFullScreen] = useState(false);
  const handleResetRecord = () => {
    window.location.reload();
  };

  const [showRecordButtons, setShowRecordButtons] = useState(true);

  const handleMouseEnter = () => {
    setShowRecordButtons(true);
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      setShowRecordButtons(false);
    }, 1000);
  };

  const handleCreateVideo = () => {
    setOpenProductPopUp(true);
  };
  const handleUploadVideo = () => {
    setOpenChooseProduct(true);
  };

  const handleShowRecordButtons = (event) => {
    event.preventDefault();
    setShowRecordButtons(!showRecordButtons);
  };

  const handleRecordVideoClick = () => {
    if (!userData) {
      navigate("/login");
    } else {
      handleCreateVideo();
      // closeRecord(() => (openRecord = false));
    }
    setShowCreateOption(false);
  };
  const handleUploadVideoClick = () => {
    if (!userData) {
      navigate("/login");
    } else {
      handleUploadVideo();
      // closeRecord(() => (openRecord = false));
    }
    setShowCreateOption(false);
  };

  const createOptionsRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        createOptionsRef.current &&
        !createOptionsRef.current.contains(event.target)
      ) {
        setShowCreateOption(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [createOptionsRef]);

  const handleCloseSnackbar = () => {
    setIsLoading(false);
  };

  return (
    <>
      <CustomSnackbar
        openSnackbar={isLoading}
        message={"Please wait we are processing your video..."}
        closeSnackbar={handleCloseSnackbar}
        autoHideDuration={null}
      />
      <div className={style["create-video"]}>
        <button
          className={style["create-video-button"]}
          onClick={handleCreateVideoButton}
          disabled={recordStarted}
          // onClick={handleOpen}
        >
          <RiVideoAddFill
            className={style["icon"]}
            style={{ color: "#FFFFFF" }}
            size={24}
          />
          <span className={style["span-title"]} style={{ color: "#FFFFFF" }}>
            Create Video
          </span>
        </button>
        <div
          className={style["create-options-container"]}
          ref={createOptionsRef}
        >
          {showCreateOption && userData && (
            <div className={style["create-options"]}>
              {createVideoItems.map((item, index) => {
                const onClickHandler =
                  item.title === "Record Video"
                    ? handleRecordVideoClick
                    : item.title === "Upload Video"
                    ? handleUploadVideoClick
                    : null;

                return (
                  <div key={index} className={style["nav-link-container"]}>
                    <div className={style["nav-link"]} onClick={onClickHandler}>
                      <div className={style["icon"]} style={{ color: "black" }}>
                        <img
                          width={`${
                            item.title == "Record Video" ? "30px" : "22px"
                          }`}
                          style={{
                            width: `${
                              item.title == "Record Video" ? "30px" : "22px"
                            }`,
                            marginRight: `${
                              item.title == "Record Video" ? "0px" : "3px"
                            }`,
                            marginLeft: `${
                              item.title == "Record Video" ? "0px" : "3px"
                            }`,
                          }}
                          src={item.icon}
                          alt={item.title}
                        />
                      </div>
                      <span
                        className={style["span-title"]}
                        style={{
                          fontSize: "15px",
                          color: "#000000",
                          marginLeft: `${
                            item.title == "Record Video" ? "0px" : "0px"
                          }`,
                        }}
                      >
                        {item.title}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {/* camera */}
      {recordStarted && (
        <>
          {optionVideo === "Camera" || fullScreen ? (
            <div
              className={`${
                recordStarted || fullScreen
                  ? "camera-full-screen"
                  : "remove-full-screen"
              }      
          `}
            >
              {withCamera && (
                <video
                  id="videoElement"
                  autoPlay={true}
                  muted={true}
                  controls={false}
                  type={mimtype}
                  style={{ transform: "scaleX(-1)" }}
                ></video>
              )}
              {recordStarted && (
                <Draggable>
                  <div
                    className={`${
                      !showRecordButtons ? "bubble-container" : ""
                    } record-buttons `}
                  >
                    <img
                      src={record_bubble}
                      alt="bubble"
                      className="bubble"
                      onClick={handleShowRecordButtons}
                      onTouchStart={handleShowRecordButtons}
                    />
                    {showRecordButtons && (
                      <div className="all-buttons">
                        <span
                          ref={recordOffSpan}
                          className="recordOff"
                          onClick={RecordOff}
                          onTouchStart={RecordOff}
                        >
                          {stop_icon}
                        </span>
                        {/* <span>{start_icon}</span> */}
                        <span className="timer ">
                          {formatTime(elapsedTime)}
                        </span>
                        <span className="hr ">|</span>
                        {/* {withCamera && (
                          <>
                            <div
                              className="small-circle"
                              onClick={() => setCameraSize("small")}
                              // onTouchStart={() => setCameraSize("small")}
                            >
                              <span>.....</span>
                            </div>
                            <div
                              className="big-circle"
                              onClick={() => setCameraSize("big")}
                              // onTouchStart={() => setCameraSize("big")}
                            >
                              <span>.....</span>
                            </div>
                          </>
                        )} */}
                        <img
                          src={trash}
                          alt=""
                          onClick={handleResetRecord}
                          // onTouchStart={handleResetRecord}
                        />
                      </div>
                    )}
                  </div>
                </Draggable>
              )}
            </div>
          ) : (
            <div className="draggable-container">
              <Draggable>
                <div
                  className="draggable-content"
                  // onMouseEnter={handleMouseEnter}
                  // onMouseLeave={handleMouseLeave}
                  // onStart={handleDragStart}
                  // onStop={handleDragStop}
                >
                  {/* {optionVideo == "Screen and Camera" && recordStarted  (
                    <video
                      id="videoElement"
                      autoPlay={true}
                      muted={true}
                      controls={false}
                      className={`${
                        cameraSize === "small" ? "small-camera" : "big-camera"
                      }`}
                      type={mimtype}
                    ></video>
                  )} */}
                  {recordStarted &&
                  (optionVideo === "Screen" ||
                    optionVideo === "Screen and Camera") ? (
                    <div
                      className={`${
                        !showRecordButtons ? "bubble-container" : ""
                      } record-buttons ${
                        cameraSize === "big" ? "buttons-on-big" : ""
                      }`}
                    >
                      {/* {!showRecordButtons && ( */}
                      <img
                        src={record_bubble}
                        alt="bubble"
                        className="bubble"
                        onClick={handleShowRecordButtons}
                        onTouchStart={handleShowRecordButtons}
                      />
                      {/* )} */}
                      {showRecordButtons && (
                        <div className="all-buttons">
                          <img
                            src={record_stop}
                            alt="stop"
                            className="stop-icon"
                            onClick={RecordOff}
                            onTouchStart={RecordOff}
                          />
                          {/* <span>{start_icon}</span> */}
                          <span className="timer">
                            {formatTime(elapsedTime)}
                          </span>
                          <span className="hr ">|</span>
                          {/* {withCamera && (
                            <>
                              <div
                                className="small-circle"
                                onClick={() => setCameraSize("small")}
                                // onTouchStart={() => setCameraSize("small")}
                              >
                                <span>.....</span>
                              </div>
                              <div
                                className="big-circle"
                                onClick={() => setCameraSize("big")}
                                // onTouchStart={() => setCameraSize("big")}
                              >
                                <span>.....</span>
                              </div>
                            </>
                          )} */}
                          <img src={trash} alt="" onClick={handleResetRecord} />
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Draggable>
            </div>
          )}
        </>
      )}

      <Modal
        open={counterModal}
        onClose={handleCloseCounterModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="counter">
          <span>{counter}</span>
        </div>
      </Modal>

      <Modal
        open={openRecordPopUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="box">
          <img className="logo" src={logo} alt="logo" />
          <div className="div">
            <form action="" onSubmit={handleStartRecording}>
              <span>Video Settings</span>

              {/* video options */}
              <div className="options-container">
                <div className="select-box">
                  <div className="selected-item" onClick={toggleDropdown1}>
                    {optionVideo && (
                      <>
                        <div className="select-inside">
                          <img
                            src={
                              optionVideo === "Screen and Camera"
                                ? screen_cam
                                : optionVideo === "Camera"
                                ? cam
                                : screen
                            }
                            alt="selected item"
                          />
                          <span>{optionVideo}</span>
                        </div>
                        <svg
                          className={` ${isOpen1 ? "rotate-arrow" : ""}`}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M3.293 7.293a1 1 0 011.414 0L10 12.586l5.293-5.293a1 1 0 111.414 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </>
                    )}
                  </div>
                  {isOpen1 && (
                    <div className="options">
                      <div
                        className="option"
                        onClick={() => {
                          setOptionVideo("Screen and Camera");
                          setCamState(true);
                          toggleDropdown1();
                        }}
                      >
                        <img src={screen_cam} alt="screen cam" />
                        <span>Screen and Camera</span>
                      </div>
                      <div
                        className="option"
                        onClick={() => {
                          setOptionVideo("Camera");
                          setCamState(true);
                          toggleDropdown1();
                        }}
                      >
                        <img src={cam} alt="camera" className="camera-icon" />
                        <span>Camera</span>
                      </div>
                      <div
                        className="option "
                        onClick={() => {
                          setOptionVideo("Screen");
                          toggleDropdown1();
                        }}
                      >
                        <img
                          src={screen}
                          alt="screen"
                          className="screen-icon"
                        />
                        <span>Screen</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <span className="span ">Recording Settings</span>

              {/* camera options */}
              <div className="camera-options">
                <div
                  className="mic-img-container "
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={handleCamState}
                >
                  <img src={camState ? cam_on : cam_off} alt="cam" />
                </div>
                <div className="options-container">
                  <div
                    className={`select-box ${!camState ? "disabled" : ""}`}
                    style={{
                      pointerEvents: !camState ? "none" : "auto",
                    }}
                  >
                    <div
                      className="selected-item "
                      style={{
                        padding: "8px",
                        backgroundColor: !camState ? "#F2F1F4" : "white",
                      }}
                      onClick={toggleDropdown2}
                    >
                      {optionCam && (
                        <>
                          <div className="select-inside devices">
                            <span>{optionCam}</span>
                          </div>
                          <svg
                            className={` ${isOpen2 ? "rotate-arrow" : ""}`}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M3.293 7.293a1 1 0 011.414 0L10 12.586l5.293-5.293a1 1 0 111.414 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </>
                      )}
                    </div>
                    {isOpen2 && optionVideo !== "Screen" && (
                      <div className="options ">
                        {cameras != undefined &&
                          cameras.map((device, index) => (
                            <div
                              key={`device - ${index}`}
                              className="option "
                              onClick={() => {
                                setOptionCam(
                                  optionVideo !== "Screen" ? device.label : ""
                                );
                                toggleDropdown2();
                                handleCameraSelection(device);
                              }}
                            >
                              <span
                                style={{
                                  color: !camState ? "#9797A5" : "#212121",
                                }}
                              >
                                {device.label}
                              </span>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* mic options */}
              <div className="camera-options">
                <div
                  className="mic-img-container"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={handleMicState}
                >
                  <img src={micState ? mic : mic_off} alt="" />
                </div>
                <div className="options-container">
                  <div
                    className={`select-box ${!micState ? "disabled" : ""}`}
                    style={{
                      pointerEvents: !micState ? "none" : "auto",
                    }}
                  >
                    <div
                      className="selected-item "
                      style={{
                        padding: "8px",
                        backgroundColor: !micState ? "#F2F1F4" : "white",
                      }}
                      onClick={toggleDropdown3}
                    >
                      {optionMic && (
                        <>
                          <div className="select-inside devices">
                            <span>{optionMic}</span>
                          </div>
                          <svg
                            className={` ${isOpen3 ? "rotate-arrow" : ""}`}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M3.293 7.293a1 1 0 011.414 0L10 12.586l5.293-5.293a1 1 0 111.414 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </>
                      )}
                    </div>
                    {isOpen3 && (
                      <div className="options ">
                        {microphones != undefined &&
                          microphones.map((device, index) => (
                            <div
                              key={`device - ${index}`}
                              className="option "
                              onClick={() => {
                                setOptionMic(device.label);
                                toggleDropdown3();
                                handleMicrophoneSelection(device);
                              }}
                            >
                              <span
                                style={{
                                  color: !micState ? "#9797A5" : "#212121",
                                }}
                              >
                                {device.label}
                              </span>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <span
                className="advanced-options "
                onClick={() => setShowAdvanced(!showAdvanced)}
              >
                Show advanced options
              </span>
              {showAdvanced && (
                <>
                  <span className="ao">Advanced Options</span>
                  <div className="advanced-container">
                    <span>Recording Countdown</span>
                    <label className="switch">
                      <input
                        type="checkbox"
                        id="toggle"
                        value={countDown}
                        onChange={() => setCountdown((prev) => !prev)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </>
              )}
              <button
                type="submit"
                className="start "
                onClick={() => {
                  handleClose();

                  recordOn();
                }}
              >
                Start recording
              </button>
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalContent;
