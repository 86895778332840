import React, { createContext, useState, useContext } from "react";

export const Context = createContext();

export const UserProvider = ({ children }) => {
  const storedUser = window.localStorage.getItem("user");
  const initialUserData = storedUser ? JSON.parse(storedUser) : null;

  const [userData, setUserData] = useState(initialUserData);
  const setUser = (user) => {
    setUserData(user);
  };

  const [openProductPopUp, setOpenProductPopUp] = useState(false);
  const [openChooseProduct, setOpenChooseProduct] = useState(false);
  const [openRecordPopUp, setOpenRecordPopUp] = useState(false);
  const [newProduct, setNewProduct] = useState(0);
  const [newVideoProduct, setNewVideoProduct] = useState(0);
  const [blobFile, setBlobFile] = useState({});
  const [productList, setProductList] = useState([]);
  const [userProductList, setUserProductList] = useState([]);
  const [displayVideos, setDisplayVideos] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isSearchingProducts, setIsSearchingProducts] = useState(false);
  const [totalSearchPagesProducts, setTotalSearchPagesProducts] =
    useState(false);
  const [videoFile, setVideoFile] = useState([]);
  const [userQuery, setUserQuery] = useState("");
  const [tags, setTags] = useState();
  const [mood, setMood] = useState("Excited");
  const [goal, setGoal] = useState("Career Growth");
  const [interest, setInterest] = useState("Design");
  const [products, setProducts] = useState([]);
  const cachedData = sessionStorage.getItem("videosData");
  const initialVideos = cachedData ? JSON.parse(cachedData) : [];
  const [allVideos, setAllVideos] = useState(initialVideos);
  const [displayProducts, setDisplayProducts] = useState([]);
  return (
    <Context.Provider
      value={{
        mood,
        setMood,
        goal,
        setGoal,
        interest,
        setInterest,
        userData,
        displayVideos,
        setDisplayVideos,
        productList,
        setProductList,
        videoFile,
        setVideoFile,
        setUser,
        userProductList,
        setUserProductList,
        openProductPopUp,
        newVideoProduct,
        setNewVideoProduct,
        setOpenProductPopUp,
        setOpenChooseProduct,
        openChooseProduct,
        openRecordPopUp,
        setOpenRecordPopUp,
        blobFile,
        setBlobFile,
        newProduct,
        setNewProduct,
        userQuery,
        setUserQuery,
        tags,
        setTags,
        products,
        setProducts,
        allVideos,
        setAllVideos,
        displayProducts,
        setDisplayProducts,
        isSearching,
        setIsSearching,
        isSearchingProducts,
        setIsSearchingProducts,
        totalSearchPagesProducts,
        setTotalSearchPagesProducts,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useUser = () => useContext(Context);
