import React, { createContext, useState, useContext } from "react";

const RecordingContext = createContext();

export const useRecording = () => useContext(RecordingContext);

export const RecordingProvider = ({ children }) => {
  const [recordStarted, setRecordStarted] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(60000);
  const [showRecordButtons, setShowRecordButtons] = useState(false);

  return (
    <RecordingContext.Provider
      value={{
        recordStarted,
        setRecordStarted,
        elapsedTime,
        setElapsedTime,
        showRecordButtons,
        setShowRecordButtons,
      }}
    >
      {children}
    </RecordingContext.Provider>
  );
};
