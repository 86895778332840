import React from "react";
import { header_bg, oops } from "../../../assets/images";
import PrimaryButton from "../../../components/primary-button/PrimaryButton";
import { useNavigate } from "react-router";
import style from "./page404.module.scss";

const Page404 = () => {
  const backgroundStyle = {
    backgroundImage: `url(${header_bg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const navigate = useNavigate();

  return (
    <div style={backgroundStyle}>
      <div className={`${style["container"]}`}>
        <div className={`${style["left-section"]}`}>
          <h1>
            Uh oh, Page is <br />
            broken.
          </h1>
          <p>
            Don’t panic, we won’t need <br />
            to put it in nice.
          </p>
          <PrimaryButton
            text="Return to Home page"
            onClick={() => navigate("/")}
          />
        </div>
        <img src={oops} alt="oops" />
      </div>
    </div>
  );
};

export default Page404;
